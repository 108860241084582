var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"items-per-page":_vm.items.length,"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"hide-default-footer":"","no-data-text":"لا توجد بيانات","loading-text":"جاري تحميل البيانات","id":_vm.$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'},scopedSlots:_vm._u([{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.note == "" ? "لا يوجد" : item.note))])]}},{key:"item.isConfirmed",fn:function(ref){
var item = ref.item;
return [(item.isConfirmed == true)?_c('v-chip',{attrs:{"color":"teal"}},[_vm._v("مستلمة")]):_vm._e(),(item.isConfirmed == false)?_c('v-chip',{attrs:{"color":"error"}},[_vm._v("غير مستلمة")]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"grey--text"},[_vm._v("("+_vm._s(item.created.substr(11,8))+")")]),_vm._v(" "+_vm._s(item.created.substr(0,10))+" ")])]}},{key:"item.scheduale",fn:function(ref){
var item = ref.item;
return [(item.scheduale == '0001-01-01T00:00:00')?_c('div',[_vm._v("--:--:--")]):_c('div',[_c('span',{staticClass:"grey--text"},[_vm._v("("+_vm._s(item.scheduale.substr(11,8))+")")]),_vm._v(" "+_vm._s(item.scheduale.substr(0,10))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$store.state.user.roles.find(function (x) { return x.key == ((_vm.$route.path.slice(1)) + "-update"); })|| _vm.$store.state.user.userType.id == _vm.$global.state.roleType.admin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" fi fi-rr-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("تعديل")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){return _vm.deleteItem(item.id, _vm.index)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" fi fi-rr-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("حذف")])])]}}])})],1),_c('Pagination'),_c('Dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }