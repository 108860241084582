<template>
<div>

    <!-- <v-row align="center" class=" mb-3">
        <v-col cols="12" md="2">
            <v-btn @click="openAddDialog()" color="primary" large class="btn" block>
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3> إنشاء قيد جديد</h3>
            </v-btn>
        </v-col>
        <v-spacer />

        <aTextField v-model="filter.title" label="بحث حسب عنوان القيد" :cols="12" :md="2" :required="false" :fieldTitle="true" :dense="true"/>
        <aAutoComplete v-model="filter.transactionCategoryId" :loading="$global.state.loading" :fieldTitle="true" :items="transactionCategories" item-text="name" item-value="id" label="صنف القيد" :cols="12" :md="2" :required="false" :dense="true"/>
        <aAutoComplete v-if="$store.state.user.userType.id == 1" v-model="filter.officeId" :loading="$global.state.loading" :fieldTitle="true" :items="offices" item-text="name" item-value="id" label="المكتب" :cols="12" :md="2" :required="false" :dense="true"/>

    </v-row> -->
    
    <v-card outlined>
        <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">

            <template v-slot:item.note="{item}">
                <div>{{ item.note == "" ? "لا يوجد" : item.note }}</div>
            </template>

            <template v-slot:item.isConfirmed="{item}">
                <v-chip v-if="item.isConfirmed == true" color="teal">مستلمة</v-chip>
                <v-chip v-if="item.isConfirmed == false" color="error">غير مستلمة</v-chip>
            </template>

            <template v-slot:item.created="{item}">
                <div><span class="grey--text">({{ item.created.substr(11,8) }})</span>  {{ item.created.substr(0,10)  }} </div>
            </template>

            <template v-slot:item.scheduale="{item}">
                <div v-if="item.scheduale == '0001-01-01T00:00:00'">--:--:--</div>
                <div v-else><span class="grey--text">({{ item.scheduale.substr(11,8) }})</span>  {{ item.scheduale.substr(0,10)  }} </div>
            </template>

            <template v-slot:item.actions="{item}">

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-update`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" x-small icon>
                            <v-icon color="info">
                                fi fi-rr-edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>

                <v-tooltip bottom="">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id, index)" v-on="on" icon x-small class="mr-2">
                            <v-icon color="error">
                                fi fi-rr-trash
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>

            </template>
        </v-data-table>
    </v-card>
    <Pagination />
    <Dialog />
</div>
</template>

<script>
export default {

    components: {
        Dialog: () => import("./dialog"),
    },

    data() {
        return {
            loading: false,
            items: [],
            offices: [],
            transactionCategories: [],
            currentIndex: null,
            filter: {
                title: '',
                transactionCategoryId: '',
                officeId: ''
            },
            headers: [
                {
                    text: "المحتوى",
                    value: "content"
                },
                {
                    text: "عدد القطع",
                    value: "itemsCount"
                },
                {
                    text: "عدد القطع المستلمة",
                    value: "realItemsCountRecived"
                },
                {
                    text: "التاجر",
                    value: "merchant.fullName"
                },
                {
                    text: "محافظة التاجر",
                    value: "merchant.governorate.name"
                },
                {
                    text: "منطقة التاجر",
                    value: "merchant.area.name"
                },
                {
                    text: "وقت استلام الطلبات",
                    value: "scheduale"
                },
                {
                    text: "تاريخ الانشاء",
                    value: "created"
                },
                {
                    text: "ملاحظات",
                    value: "note"
                },
                {
                    text: "الحالة",
                    value: "isConfirmed"
                },
                
                // {
                //     text: "الاجراءات",
                //     value: "actions"
                // }
            ],

        }
    },

    created() {

        this.getItems()
        this.getOffices()
        setTimeout(() => {
            this.$eventBus.$on(`${this.$route.path}`, () => {
                this.getItems()
            })
        }, 100);

    },

    beforeDestroy() {
        this.$eventBus.$off(`${this.$route.path}`)
    },

    methods: {
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Todo?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getOffices() {
                this.$global.state.loading = true
                try {
                    const res = await this.$http.get(`Office`)
                    this.offices = res.data.result
                } catch (err) {
                    console.log(err)
                    this.$service.faild(err.response.data.message)
                } finally {
                    this.$global.state.loading = false
                }
            },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fetch-data')

        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails', item)
            this.$store.commit('setDialog')
            this.$eventBus.$emit('fill-fields')
        },

    },

}
</script>
